import { defineStore } from 'pinia';
import { getNodeTypeList, getOrg, getOrgPosition, getOrgTree } from '@/api/organizing';

interface State {
  orgId: number;
  orgInfo?: ApiOrg.OrgInfo;
  // 加载状态 0未加载; 1加载中; 2已加载
  loadOrgInfoStatus: number;
  nodeTypeList: ApiOrg.NodeType[];
  // 岗位列表
  positionList: ApiOrg.PostInfo[];
  // 部门加载状态 加载状态 0未加载; 1加载中; 2已加载
  loadDepartmentTreeStatus: number;
  departmentTree?: PageOrg.DepartmentTreeNode[];
  // 是否加载结束
  isEnd: boolean;
}

// 组织列表信息相关 store
const useOrganizingStore = defineStore('organizingStore', {
  state: (): State => ({
    orgId: 0,
    loadOrgInfoStatus: 0,
    positionList: [],
    nodeTypeList: [],
    isEnd: false,
    loadDepartmentTreeStatus: 0
  }),
  actions: {
    async tryGetOrg(orgId: number) {
      if (orgId === this.orgId) {
        return;
      }
      await this.reloadOrg(orgId);
    },
    async reloadOrg(orgId: number) {
      try {
        this.orgId = orgId;
        this.loadOrgInfoStatus = 1;
        this.orgInfo = undefined;
        this.positionList = [];
        this.nodeTypeList = [];
        const data = await getOrg({ orgId });
        this.orgInfo = data.data.organization;
        console.log('this.orgInfo', this.orgInfo);
        this.loadOrgInfoStatus = 2;
      } catch (e: any) {
        console.error('e', e);
      }
    },
    // 尝试加载 node type list
    async tryLoadNodeType() {
      if (this.nodeTypeList.length > 0) {
        return;
      }
      const data = await getNodeTypeList();
      this.nodeTypeList = data.data.nodeTypeList;
    },
    // 加载岗位列表
    async reloadPositionList() {
      const data = await getOrgPosition({ orgId: this.orgId });
      this.positionList = data.data.positionList;
    },
    // 尝试加载岗位列表
    async tryLoadPositionList() {
      if (this.positionList.length > 0 || this.isEnd) {
        return;
      }
      await this.reloadPositionList();
      this.isEnd = true;
    },
    // 尝试加载部门树
    async tryLoadDepartmentTree() {
      if (this.loadDepartmentTreeStatus === 0) {
        await this.loadDepartmentTree();
      }
    },
    async loadDepartmentTree() {
      try {
        this.loadDepartmentTreeStatus = 1;
        const res = await getOrgTree({
          orgId: this.orgId,
          depth: 5
        });
        this.departmentTree = parseToDepartmentTree([res.data.orgTree]);
        console.log('this.departmentTree', JSON.stringify(this.departmentTree));
        this.loadDepartmentTreeStatus = 2;
      } catch (e: any) {
        console.error('加载部门失败');
        this.loadDepartmentTreeStatus = 0;
      }
    },
    // 部门发生变化
    changeDepartment() {
      this.loadDepartmentTreeStatus = 0;
    }
  }
});

function parseToDepartmentTree(info?: ApiOrg.OrgTreeInfo[]) {
  const result: PageOrg.DepartmentTreeNode[] = [];
  info?.forEach((value) => {
    result.push({
      label: value.node.name,
      value: value.node.id,
      children: parseToDepartmentTree(value.children)
    });
  });
  return result;
}

export { useOrganizingStore };
