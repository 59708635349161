// 员工状态 0离职 1 实习 2在职
export const AllEmployeeStatus = [
  { label: '实习', value: 1 },
  { label: '在职', value: 2 },
  { label: '离职', value: 0 }
];

// 工作类型 1正式 2外包 3合作
export const AllTypeJob = [
  { label: '正式', value: 1 },
  { label: '外包', value: 2 },
  { label: '合作', value: 3 }
];
